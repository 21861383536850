<template>
  <div>
    <div v-if="loading" class="flex sm12 lg12">
      <div class="text--center pb-4">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </div>
    <div v-if="controlGroupsData && !loading" class="cards">
      <div class="cards-container row d-flex wrap align--start">
        <template v-for="controlGroup in controlGroupsData">
          <overview-card :key="controlGroup.id"
            context="room"
            :card-class="'flex xs6'"
            :id="controlGroup.id"
            :name="controlGroup.name"
            :settings-now="currentData[controlGroup.id]"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import * as socketio from '../../../utilities/socketio'
import OverviewCard from '../../../services/atmosphere-ui/components/cards/at-overview-card/AtOverviewCard'

export default {
  name: 'growing-farm-cards',
  data () {
    return {
      loading: false,
      notFound: false,
      dataNames: ['temperature', 'relative_humidity', 'co2', 'volume_flow'],
      controlGroupsData: null,
      currentData: {},
    }
  },
  created () {
    this.$level.value = 'airplane'
    this.initializeView()
  },
  computed: {
    activeFarm () {
      return this.$farmId ? this.$farmId : parseInt(process.env.VUE_APP_FARM_ID)
    },
  },
  methods: {
    initializeView () {
      this.loading = true
      socketio.initialiseSocket()
      socketio.socket.emit('get_all_control_groups', this.activeFarm, { per_page: 15 })
      socketio.socket.once('get_all_control_groups_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
          this.notFound = true
        } else {
          this.controlGroupsData = (parsed.data.data).sort((a, b) => (a.id > b.id ? 1 : -1))
          this.initializeCards()
        }
      })
    },
    initializeCards () {
      for (let i = 0; i < this.controlGroupsData.length; i++) {
        this.currentData = {
          ...this.currentData,
          [this.controlGroupsData[i].id]: {},
        }
        for (let j = 0; j < this.dataNames.length; j++) {
          this.currentData[this.controlGroupsData[i].id] = {
            ...this.currentData[this.controlGroupsData[i].id],
            [this.dataNames[j]]: {},
          }
        }
        this.listenCurrentData(this.controlGroupsData[i].id)
      }
      this.loading = false
    },
    listenCurrentData (controlGroupId) {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`control_group_${controlGroupId}`])
      socketio.socket.on('control_group_air_conditions_current_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status_code >= 400 && parsed.status_code > 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.control_group_id === controlGroupId) {
            for (let j = 0; j < this.dataNames.length; j++) {
              this.currentData[controlGroupId][this.dataNames[j]] = parsed.data.data[this.dataNames[j]]
            }
          }
        }
      })
    },
  },
  watch: {
    $route () {
      this.initializeView()
    },
    notFound () {
      if (this.notFound) {
        this.loading = false
        this.$router.push({ path: '/404/page-not-found' })
      }
    },
  },
  components: {
    OverviewCard,
  },
  beforeDestroy () {
    for (let i = 0; i < this.controlGroupsData.length; i++) {
      socketio.socket.emit('unsubscribe_from_context', [`control_group_${this.controlGroupsData[i].id}`])
    }
  },
}
</script>
